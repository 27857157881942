import Send from '../../trans.client.js'

//주석정의방법 : 컨트롤러 메소드명 - 메소드에 대한 간단 주석 - REST URI
export default {
  getDangerDocInfo (data) {
    return Send({
      url: '/trans/docs/' + data.bkgNo + '/danger-doc-info',
      method: 'get',
      params: {
        reqCatCd: '05', // 위험물 문서 고정
        kind: 'BK'
      }
    })
  },
  getDangerDocSimple (data) {
    return Send({
      url: '/trans/docs/' + data.bkgNo + '/danger-doc-simple',
      method: 'get',
      params: {
        reqCatCd: data.reqCatCd,
        kind: data.kind,
        reqRno: data.reqRno
      }
    })
  },
  getCntReqMas (data) {
    return Send({
      url: '/trans/docs/cnt-req',
      method: 'get',
      params: {
        blNo: data.blNo,
        reqCatCd: data.reqCatCd
      }
    })
  },
  reqDocSubmit (data) {
    return Send({
      url: '/trans/docs/insert-req-doc',
      method: 'post',
      data: data
    })
  },
  containerChk (cntrNo) {
    return Send({
      url: '/trans/docs/' + cntrNo + '/container-check',
      method: 'get',
      params: {
      }
    })
  },
  getReqDocBookingBl (blNo, bkgNo, type) {
    console.log('type', type)
    if (type === undefined || type === '') {
      type = 'BL'
    }
    return Send({
      url: '/trans/docs/' + blNo + '/bl-cntr-doc-info',
      method: 'get',
      params: {
        type: type,
        bkgNo: bkgNo
      }
    })
  },
  getReqDocBooking (bkgNo) {
    return Send({
      url: '/trans/docs/' + bkgNo + '/bkg-cancel-doc-info',
      method: 'get'
    })
  },
  getBkgContainerInfo (blNo) {
    return Send({
      url: '/trans/docs/' + blNo + '/bl-cntr-list',
      method: 'get'
    })
  },
  getReqDocTemp (bkgNo, type) {
    return Send({
      url: '/trans/docs/' + bkgNo + '/req-doc-temp-info',
      method: 'get',
      params: {
        type: type
      }
    })
  },
  findReqChk (params) {
    return Send({
      url: '/trans/docs/' + params.bkgNo + '/find-req-chk',
      method: 'get',
      params: {
        reqCatCd: params.reqCatCd
      }
    })
  },
  //컨테이너 로그 가져오기
  getContainerLog (formData) {
    return Send({
      method: 'get',
      url: '/trans/docs/' + formData.blNo + '/container-log',
      params: {
        bkgNo: formData.bkgNo,
        cntrNo: formData.cntrNo,
        cntrStsCd: formData.cntrStsCd
      }
    }).catch((err) => {
      console.log(err)
    })
  },
  //컨테이너 로그 카운트 가져오기
  getContainerLogCnt (formData) {
    return Send({
      method: 'get',
      url: '/trans/docs/' + formData.blNo + '/container-log-cnt',
      params: {
        bkgNo: formData.bkgNo,
        cntrNo: formData.cntrNo,
        cntrStsCd: formData.cntrStsCd
      }
    }).catch((err) => {
      console.log(err)
    })
  },
  //해당 컨테이너의 가장 최근에 GATE-IN 되었던 터미널 가져오기
  findGtiTerminal (cntrNo) {
    return Send({
      method: 'get',
      url: '/trans/docs/' + cntrNo + '/container-log-no-bl',
      params: {
      }
    }).catch((err) => {
      console.log(err)
    })
  },
  getOriginalBLReIssueInfo (params) {
    return Send({
      url: '/trans/docs/' + params.blNo + '/original-bl-re-issue',
      method: 'get',
      params: params
    })
  },
  findCtrCd () {
    return Send({
      url: '/trans/docs/findCtrCd',
      method: 'get',
      params: {}
    })
  },
  findPlcCd (ctrCd) {
    return Send({
      url: '/trans/docs/' + ctrCd + '/findPlcCd',
      method: 'get',
      params: {
        ctrCd: ctrCd
      }
    })
  },
  getExcelDownload (data) {
    return Send({
      url: '/trans/docs/' + data.blNo + '/doc-excel-download',
      method: 'get',
      responseType: 'blob',
      params: {
        fileName: data.fileNm
      }
    })
  },
  getBlSurrCopyInfo (blNoInfo) {
    return Send({
      url: '/trans/docs/surr-bl-copy-list',
      method: 'get',
      params: {
        arrBlNo: blNoInfo.join(',')
      }
    })
  },
  getReqDocTempBkgInfo (data) {
    return Send({
      url: '/trans/docs/' + data.bkgNo + '/req-doc-temp-bkg-info',
      method: 'get',
      params: {
        type: 'BK'
      }
    })
  },
  reqDocTempSubmit (data) {
    return Send({
      url: '/trans/docs/insert-req-doc-temp',
      method: 'post',
      data: data
    })
  },
  getReqDocTempInfo (data) {
    return Send({
      url: '/trans/docs/' + data.bkgNo + '/req-doc-temp-info',
      method: 'get',
      params: {
        blNo: data.blNo,
        reqCatCd: data.reqCatCd,
        type: 'BK'
      }
    })
  },
  reqDocTempDelete (data) {
    return Send({
      url: '/trans/docs/delete-req-doc',
      method: 'post',
      data: data
    })
  },
  checkCntrInBlBkg (params) {
    return Send({
      url: '/trans/docs/check-cntr',
      method: 'get',
      params: params
    })
  }
}
